// @ts-nocheck
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';

import usePosts from '@/hooks/contentful/usePosts';
import { getPostValues } from '@/utils/posts';

import { CategoryChipTransparent } from '../styles';
import { dateFormat, truncateString } from '../utils';
import {
  CardContainer,
  CardContentText,
  CategoryStack,
  DateCard,
  GridContainer,
  GridItem,
  LinkCard,
  LoadingSkeletonPost,
  TitleCard,
} from './styles';

import type { Post } from '@/@types/contentful';

interface ArticlesProps {
  search?: string;
  skip?: number;
  limit?: number;
  filter?: Record<string, string>;
}
interface ArticleCardProps {
  post: Post;
}

const Articles = ({ search, skip, limit, filter }: ArticlesProps) => {
  const { posts, isLoading } = usePosts(search, skip, limit, filter);

  if (posts.length === 0) return <NoPosts />;

  return (
    <Container>
      <GridContainer container>
        {posts.map((post) => {
          const data = getPostValues(post);

          return (
            <>
              {isLoading ? (
                <LoadingSkeletonPost
                  sx={{ bgcolor: '#DAEDC6' }}
                  animation="pulse"
                  variant="rectangular"
                />
              ) : (
                <GridItem>
                  <LinkCard key={data.postId} href={data.url}>
                    <ArticleCard post={post} />
                  </LinkCard>
                </GridItem>
              )}
            </>
          );
        })}
      </GridContainer>
    </Container>
  );
};

export const ArticleCard = ({ post }: ArticleCardProps) => {
  const data = getPostValues(post);
  const categories = post.fields.categories;
  return (
    <CardContainer>
      <CardMedia component="img" height={282} image={data.image} alt={data.imageAlt} />
      <CardContentText>
        <CategoryStack>
          <DateCard>{dateFormat(data.createdAt)}</DateCard>

          {categories.map((category) => (
            <CategoryChipTransparent
              key={category?.sys.id}
              label={category?.fields.categoryName}
              variant="filled"
              color="primary"
              size="small"
            />
          ))}
        </CategoryStack>

        <TitleCard>{truncateString(data.title)}</TitleCard>
      </CardContentText>
    </CardContainer>
  );
};

export const NoPosts = () => {
  const { t } = useTranslation();

  return (
    <Card sx={{ py: '2rem' }}>
      <TitleCard textAlign="center">{t('BLOG.NO_POSTS')}</TitleCard>
    </Card>
  );
};

export default Articles;
