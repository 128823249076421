import { createClient } from 'contentful';

import type { TypeCategoriesSkeleton, TypePostSkeleton } from '@/@types/contentful';

const accessToken = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN ?? 'myAccessToken';
const space = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID ?? 'mySpaceId';
const environment = process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT ?? 'dev';

export const client = createClient({ accessToken, space, environment });

const getBlogPosts = async (
  country: string,
  search?: string,
  skip?: number,
  limit?: number,
  filter?: Record<string, string>,
) =>
  await client.getEntries<TypePostSkeleton>({
    content_type: 'post',
    'fields.country.sys.contentType.sys.id': 'country',
    'fields.country.fields.id': country,
    'fields.title[match]': search,
    links_to_entry: filter?.category,
    limit,
    skip,
  });

const getPost = async (id: string) => await client.getEntry<TypePostSkeleton>(id);

const getSinglePost = async (id: string, numberOccurrences: number) =>
  await client
    .getEntries<TypePostSkeleton>({
      'fields.title[match]': id,
      content_type: 'post',
    })
    .then((response) => {
      const matchingItems = response.items.filter((item) => item.fields.title.toLowerCase() === id);
      return matchingItems[numberOccurrences - 1];
    });

const getPostsCategories = async () =>
  await client.getEntries<TypeCategoriesSkeleton>({ content_type: 'categories' });

export { getBlogPosts, getSinglePost, getPostsCategories, getPost };
