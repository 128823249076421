export function dateFormat(fecha: string | Date) {
  const date = typeof fecha === 'string' ? new Date(fecha) : fecha;
  return `${date?.toLocaleString('es-ES', {
    month: 'long',
  })} ${date?.getDate()}, ${date?.getFullYear()}`;
}

export function truncateString(inputString: string, maxLength = 63): string {
  if (inputString.length <= maxLength) {
    return inputString;
  } else {
    return inputString.substring(0, maxLength - 3) + '...';
  }
}
