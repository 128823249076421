import type { Post } from '@/@types/contentful';

interface PostValues {
  postId: string;
  titleURI: string;
  url: string;
  title: string;
  image: string;
  imageAlt: string;
  createdAt: Date;
}

export const getPostValues = (post: Post): PostValues => {
  const postId = post?.sys.id;
  const title = post?.fields?.title ?? '';
  const titleURI = post?.fields?.slug ?? '';
  const url = `/blog/${postId}/${titleURI}`;

  const image = post?.fields?.coverImage?.fields?.file?.url;
  const imageAlt = post?.fields?.coverImage?.fields?.title ?? '';

  const createdAt = new Date(post?.sys.createdAt);

  return {
    postId,
    titleURI,
    url,
    title,
    image,
    imageAlt,
    createdAt,
  };
};
