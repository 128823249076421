import { styled } from '@mui/material';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';

export const ContainerToolBar = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '1rem',
  [theme.breakpoints.down('md')]: {
    maxWidth: '95vw',
    gap: '1rem',
  },
}));

export const CategoryChip = styled(Chip)(({ theme }) => ({
  maxWidth: '5rem',
  borderRadius: '5px',
  color: 'white',
  fontWeight: theme.typography.fontWeightMedium,
}));
export const CategoryChipTransparent = styled(Chip)(({ theme }) => ({
  maxWidth: '5rem',
  borderRadius: '5px',
  background: 'rgb(106, 206, 59, 0.15)',
  color: '#6ACE3B',
  fontWeight: theme.typography.fontWeightMedium,
}));
