import Head from 'next/head';

import type { StaticImageData } from 'next/image';

interface Head99minProps {
  title?: string;
  description?: string;
  image?: StaticImageData;
  imageSrc?: string;
  url?: string;
  type?: string;
}

const Head99min = ({ title, description, image, imageSrc, url, type }: Head99minProps) => {
  return (
    <Head>
      {title != null && <title>{title}</title>}

      {description != null && <meta name="description" content={description} />}
      {image != null && <meta name="image" content={image.src} />}

      {/* Open Graph / Facebook */}
      {title != null && <meta property="og:title" content={title} />}
      {description != null && <meta property="og:description" content={description} />}
      {url != null && <meta property="og:url" content={url} />}
      {type != null && <meta property="og:type" content={type} />}
      {imageSrc != null ? (
        <>
          {imageSrc != null && <meta property="og:image" content={imageSrc} />}
          {imageSrc != null && <meta property="og:image:secure_url" content={imageSrc} />}
        </>
      ) : (
        <>
          {image != null && <meta property="og:image" content={image.src} />}
          {image != null && <meta property="og:image:secure_url" content={image.src} />}
          {image != null && <meta property="og:image:width" content={image.width.toString()} />}
          {image != null && <meta property="og:image:height" content={image.height.toString()} />}
          {image != null && <meta property="og:image:type" content={getImageType(image)} />}
        </>
      )}

      {/* Twitter */}
      {image != null && <meta name="twitter:card" content="summary_large_image" />}
      {url != null && <meta name="twitter:domain" content={getDomain(url)} />}
      {url != null && <meta name="twitter:url" content={url} />}
      {title != null && <meta name="twitter:title" content={title} />}
      {description != null && <meta name="twitter:description" content={description} />}
      {imageSrc != null ? (
        <>{imageSrc != null && <meta name="twitter:image" content={imageSrc} />}</>
      ) : (
        <>
          {image != null && <meta name="twitter:image" content={image.src} />}
          {image != null && <meta name="twitter:image:width" content={image.width.toString()} />}
          {image != null && <meta name="twitter:image:height" content={image.height.toString()} />}
          {image != null && <meta name="twitter:image:type" content={getImageType(image)} />}
        </>
      )}

      {/* Google / Schema.org */}
      {title != null && <meta itemProp="name" content={title} />}
      {description != null && <meta itemProp="description" content={description} />}

      {imageSrc != null ? (
        <>{imageSrc != null && <meta itemProp="image" content={imageSrc} />}</>
      ) : (
        <>{image != null && <meta itemProp="image" content={image.src} />}</>
      )}
    </Head>
  );
};

const getImageType = (image: StaticImageData) => {
  if (image.src.endsWith('.png')) {
    return 'image/png';
  }

  if (image.src.endsWith('.jpg') || image.src.endsWith('.jpeg')) {
    return 'image/jpeg';
  }

  if (image.src.endsWith('.gif')) {
    return 'image/gif';
  }

  if (image.src.endsWith('.webp')) {
    return 'image/webp';
  }

  if (image.src.endsWith('.svg')) {
    return 'image/svg+xml';
  }

  if (image.src.endsWith('.ico')) {
    return 'image/x-icon';
  }

  if (image.src.endsWith('.bmp')) {
    return 'image/bmp';
  }

  if (image.src.endsWith('.tiff')) {
    return 'image/tiff';
  }

  if (image.src.endsWith('.tif')) {
    return 'image/tiff';
  }

  return 'image/*';
};

const getDomain = (url: string) => {
  const domain = url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];

  return domain;
};

export default Head99min;
