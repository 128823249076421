import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const LinkCard = styled('a')(() => ({
  textDecoration: 'none',
  width: '100%',
}));

export const LoadingSkeletonPost = styled(Skeleton)(({ theme }) => ({
  marginTop: '1rem',
  height: '26.75rem',
  width: '23.5rem',
  borderRadius: '1rem 1rem 1rem 1rem',
  [theme.breakpoints.down('sm')]: {
    width: '90vw',
    margin: '0 auto',
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    width: '90%',
    margin: '0 auto',
  },
}));

export const CategoryStack = styled(Stack)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '0.5rem',
  alignItems: 'center',
}));

export const TitleCard = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  color: theme.palette.secondary.main,
  fontWeight: theme.typography.fontWeightBold,
  marginTop: '1rem',
}));

export const DateCard = styled(Typography)(({ theme }) => ({
  ...theme.typography.overline,
  color: theme.palette.grey[500],
  fontWeight: theme.typography.fontWeightRegular,
  textTransform: 'capitalize',
}));

export const CardContainer = styled(Card)(({ theme }) => ({
  height: '27.6rem',
  width: '23.5rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '0 0 2rem',
  [theme.breakpoints.down('sm')]: {
    width: '90vw',
    margin: '0 auto',
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    width: '90%',
    margin: '0 auto',
  },
  '&:hover': {
    transform: 'scale(1.05)',
    transition: 'all .3s ease-in-out',
  },
}));

export const CardContentText = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '85%',
  margin: '1rem auto',
}));

export const GridItem = styled(Box)(() => ({
  display: 'grid',
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
  marginBottom: '2.06rem',
  rowGap: '1rem',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));
