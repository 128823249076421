import { useCallback, useEffect, useState } from 'react';

import { useLayoutStore } from '@/stores/layout/layout.store';
import { getBlogPosts } from '@/utils/contentfulPosts';

import type { Post } from '@/@types/contentful';

const usePosts = (
  search?: string,
  skip?: number,
  limit?: number,
  filter?: Record<string, string>,
) => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [totalPosts, setTotalPosts] = useState<number>(0);
  const [isLoading, setLoading] = useState(true);
  const actualCountry = useLayoutStore((state) => state.country);

  const getPosts = useCallback(async () => {
    setLoading(true);

    const response = await getBlogPosts(actualCountry, search, skip, limit, filter);
    setPosts(response.items as Post[]);
    setTotalPosts(response.total);

    setLoading(false);
  }, [actualCountry, search, skip, limit, filter]);

  useEffect(() => {
    getPosts().catch((err) => console.error(err));
  }, [actualCountry, search, skip, filter]);

  return { posts, isLoading, totalPosts };
};

export default usePosts;
